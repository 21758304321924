<script>
import { mapState } from 'vuex';
import Button from './Button';

export default {
  name: 'ApproveButton',
  props: ['disabled', 'approveName', 'name', 'approveMethod'],
  data() {
    return {
      isLoadingApprove: false,
    };
  },
  components: { Button },
  computed: {
    ...mapState({
      ethAddress: (state) => state.User.ethAddress,
    }),
  },
  methods: {
    async approve() {
      this.isLoadingApprove = true;
      let tmpHash = '';
      const amount = this.$BN(2).pow(256).minus(1).toString(10);
      const contract = this.$contracts.getContract(this.name);
      let contractApprove;
      try {
        contractApprove = this.approveMethod(amount);
        if (!contractApprove) {
          contractApprove = contract
            .approve(
              this.$contracts.getContract(this.approveName).options.address,
              amount,
            )
            .send({ from: this.ethAddress });
        }
      } catch (e) {
        console.error(e);
      }

      try {
        await contractApprove.on('transactionHash', (hash) => {
          tmpHash = hash;
          this.$store.dispatch('Notification/create', {
            type: 'info',
            title: 'Pending approve:',
            data: { hash: tmpHash },
          }, { root: true });
        });
        await contractApprove();
        this.$store.dispatch('Notification/clear', 'info', { root: true });
        this.$store.dispatch('Notification/create', {
          type: 'success',
          title: 'Successfully approved!',
          data: { hash: tmpHash },
          config: { duration: 20000 },
        }, { root: true });
        this.$store.dispatch('update');
      } catch (error) {
        console.error(error);
        this.$store.dispatch('Notification/clear', 'info', { root: true });
        this.$store.dispatch('Notification/create', {
          type: 'warn',
          title: 'Approve failed!',
          config: { duration: 5000 },
        }, { root: true });
      }
      this.isLoadingApprove = false;
    },
  },
};
</script>

<template>
  <div>
    <Button :disabled="disabled || isLoadingApprove" @click="approve">
      {{ $t('product.buttons.approve') }}
    </Button>
  </div>
</template>
