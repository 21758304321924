<script>
import Button from '../Button';

export default {
  name: 'Confirm',
  components: { Button },
  props: {
    amount: {
      required: false,
    },
    action: {
      required: false,
    },
    title: {
      default: 'Confirm',
    },
    type: {
      default: '',
    },
    isLoading: {
      default: false,
    },
    symbol: {
      required: false,
    },
    isEnoughBal: {
      default: true,
    },
    show: {
      default: false,
    },
  },
  methods: {
    async confirm() {
      await this.action();
    },
  },
};
</script>

<template>
  <v-dialog
    @input="$emit('update:show', $event)"
    :value="show"
  >
    <div class="main-block modal modal--light">
      <div v-if="isLoading" class="load">
        <div class="loader">{{ $t('loading') }}</div>
        <p>{{ $t('modals.confirm.waiting') }}</p>
      </div>
      <div v-else class="confirm">
        <div>
          <h4 class="modal__ttl modal__ttl--big">
            {{ $t(`modals.confirm.title.${type}`) }}
          </h4>

          <div class="info-container" v-if="isEnoughBal">
            <div>
              {{ $t(`modals.confirm.text.${type}`) }}
            </div>
            <div v-if="amount">
              {{ amount | amount(18) }}
              {{ symbol }}
            </div>
          </div>
          <div v-else>
            <div class="info-container">
              <div>You need to burn:</div>
              <div v-if="amount">
                {{ amount | amount(18) }}
                {{ symbol }}
              </div>
            </div>
            <p class="error">{{ $t('errors.no-tokens') }}</p>
          </div>
        </div>

        <div class="modal__btns" v-if="action">
          <Button
            @click="$emit('update:show', false)"
            class="trim avalanche-btn">
              {{ $t('modals.withdraw-deposit.buttons.cancel') }}
          </Button>
          <Button
            @click="confirm"
            :disabled="isLoading || !isEnoughBal"
            class="avalanche-btn-blue"
          >{{ isLoading ?
              $t('loading')
              :
              $t(`modals.confirm.buttons.${type}`) }}
          </Button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<style lang="scss">
  .load {
    overflow: hidden;
    max-height: 255px;
    p {
      font-weight: 600;
      font-size: 1.6rem;
      text-align: center;
      color: $main-pink
    }
    .loader,
    .loader:after {
      border-radius: 50%;
      width: 10em;
      height: 10em;
    }
    .loader {
      margin: 60px auto;
      font-size: 10px;
      position: relative;
      text-indent: -9999em;
      border-top: 1.1em solid rgba(255, 0, 0, 0.4);
      border-right: 1.1em solid rgba(0, 0, 0, 0.05);
      border-bottom: 1.1em solid rgba(0, 0, 0, 0.05);
      border-left: 1.1em solid rgba(0, 0, 0, 0.05);
      -webkit-transform: translateZ(0);
      -ms-transform: translateZ(0);
      transform: translateZ(0);
      -webkit-animation: load8 1.1s infinite linear;
      animation: load8 1.1s infinite linear;
    }
    @keyframes load8 {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }

  .confirm {
    max-width: auto;

    p {
      font-weight: 600;
      font-size: 1.6rem;
      color: $main-pink;

      &.error {
        margin: 0 50px;
        margin-top: 10px;
        padding: 10px;

        text-align: center;

        color: #ffffff;
        background-color: $main-pink;
        border-radius: 10px;
      }
    }

    .info-container {
      display: flex;
      margin: 0 auto;
      width: fit-content;

      div {
        font-weight: 600;
        font-size: 1.6rem;

        &:first-child {
          padding-right: 1rem;
          color: $main-pink;
          font-weight: bold;
        }
      }
    }

    .modal__btns {
      margin-top: 5rem;
    }

    .title {
      font-weight: bold;
      font-size: 2.8rem;
      line-height: 1.5;
      text-align: center;
      letter-spacing: 0.03em;
      color: $main-font-color;
    }
  }
</style>
