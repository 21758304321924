<script>
import Confirm from '@/components/Modals/Confirm';
import Button from '@/components/Button';
import InputComponent from '@/components/InputComponent';
import { amountFilter } from '../../helpers/filters';

export default {
  name: 'Withdraw',
  components: {
    Button,
    Confirm,
    InputComponent,
  },
  props: {
    pool: {
      required: true,
    },
  },
  data: () => ({
    amount: 0,
    showConfirm: false,
    value: false,
  }),
  created() {
    this.$root.$on('showWithdraw', this.showWithdraw);
  },
  beforeDestroy() {
    this.$root.$off('showWithdraw', this.showWithdraw);
  },
  computed: {
    isLoading() {
      return this.$store.state.Actions.loading;
    },
    isDisabled() {
      return this.isLoading || parseFloat(this.amount) === 0 || this.amount === '';
    },
  },
  methods: {
    showWithdraw() {
      this.value = true;
    },
    async withdraw() {
      const withdrawAmount = this.$contracts.toWei(this.amount.replace(',', ''));
      await this.$store.dispatch('Actions/withdraw', {
        pid: this.pool.pid,
        amount: withdrawAmount.toString(10),
        text: `${this.amount} ${this.pool.symbol}`,
        callback: () => {
          this.showConfirm = false;
          this.value = false;
        },
        provider: this.pool.nft || this.pool.type,
      });
      this.amount = 0;
    },
    maximize() {
      this.amount = amountFilter(this.$BN(this.pool.lockedBalance).toString());
    },
  },
};
</script>

<template>
  <div>
    <v-dialog
      v-model="value"
    >
      <div class="modal main-block modal--light">
        <h4 class="modal__ttl modal__ttl--big">
          {{ $t('modals.withdraw-deposit.title-withdraw') }} {{ pool.symbol }}
        </h4>
        <div class="modal__input">
          <InputComponent :max="true" v-model="amount" @click="maximize">
            <template v-slot:label>
              <span>
                {{ pool.lockedBalance | amount(18) }}
                {{ pool.symbol }}
                {{ $t('modals.withdraw-deposit.available') }}
              </span>
            </template>
            <template v-slot:input>
              {{ pool.symbol }}
            </template>
          </InputComponent>
        </div>
        <div class="modal__btns">
          <Button @click="$emit('update:show', false)">
            {{ $t('modals.withdraw-deposit.buttons.cancel') }}
          </Button>
          <Button
            @click="showConfirm = true"
            :disabled="isDisabled"
          >
            {{
              isLoading ?
                $t('loading')
                :
                $t('modals.withdraw-deposit.buttons.confirm')
            }}
          </Button>
        </div>
      </div>
    </v-dialog>
    <Confirm
      :amount="amount"
      :symbol="pool.symbol"
      :action="withdraw"
      :title="'Confirm Withdraw'"
      :type="'unstake'"
      :isLoading="isLoading"
      :show.sync="showConfirm"
    />
  </div>
</template>
