<script>
import { mapState } from 'vuex';
import Deposit from '@/components/Modals/Deposit';
import Withdraw from '@/components/Modals/Withdraw';
import Button from '@/components/Button';
import ApproveButton from '@/components/ApproveButton';

export default {
  name: 'Product',
  data: () => ({
    depositType: '',
    isLoadingApprove: false,
    showWithdraw: false,
  }),
  components: {
    ApproveButton,
    Button,
    Deposit,
    Withdraw,
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    ...mapState({
      authenticated: (state) => state.User.ethAddress,
      isLoading: (state) => state.Actions.loading,
    }),
    pools() {
      return this.$store.state.Pools.list.nft;
    },
    pool() {
      return this.pools?.find((item) => Number(item.id) === Number(this.id));
    },
    isDisabled() {
      return !(this.pool.allowance >= 0);
    },
  },
  methods: {
    openDeposit(type) {
      this.depositType = type;
      this.$root.$emit('showDeposit');
    },
    openWithdraw() {
      this.$root.$emit('showWithdraw');
    },
    harvest() {
      this.$store.dispatch('Actions/deposit', {
        pid: this.pool.pid,
        amount: 0,
        type: 'harvest',
        provider: this.pool.nft,
      });
    },
  },
};
// !(pool.pendingMilk > 0) || isLoading
</script>

<template>
  <div class="main-wrap">
    <div class="page-content pool" v-if="pool">
      <img
        class="pool__icon"
        :src="require(`../../assets/img/nft/${pool.icon}.png`)"
      />
      <h1 class="page-title">{{ pool.name }}</h1>
      <div class="pool__items">
        <div class="pool-item main-block">
          <span class="pool-item__title">{{
            $t("product.your-earned", { token: "MILK2" })
          }}</span>
          <span class="pool-item__balance">{{
            pool.pendingMilk | amount(5)
          }}</span>
          <div class="pool-item__btns">
            <Button
              :disabled="true"
              @click="harvest"
            >
              {{ $t("product.buttons.harvest") }}
            </Button>
          </div>
        </div>
        <div class="pool-item main-block">
          <span class="pool-item__title">
            {{ pool.symbol }}
            <br />
            {{ $t("product.tokens-staked") }}
          </span>
          <span class="pool-item__balance">{{
            pool.lockedBalance | amount(3, true)
          }}</span>
          <div class="pool-item__btns" v-if="pool.allowance !== undefined">
            <ApproveButton
              v-if="!(pool.allowance > 0)"
              :approveMethod="pool.approve"
              name="nft"
              :disabled="isDisabled"
            />
            <Button
              v-else-if="!(pool.lockedBalance > 0)"
              :disabled="isLoading"
              @click="openDeposit('stake')"
            >
              {{ $t("product.buttons.deposit") }}
            </Button>
            <template v-else>
              <Button
                class="trim"
                :disabled="!(pool.lockedBalance > 0) || isLoading"
                @click="openWithdraw"
              >
                {{ $t("product.buttons.withdraw") }}
              </Button>
              <Button
                class="btn--round"
                @click="openDeposit('deposit')"
                :disabled="isLoading"
              >
                +
              </Button>
            </template>
          </div>
        </div>
      </div>
      <Button
        v-if="pool.tokenContract || pool.lpContract"
        class="pool__trade-btn"
        :href="
          pool.name === 'Сinnamon'
            ? `https://mooniswap.exchange/#/swap?inputCurrency=${pool.tokenContract}&outputCurrency=0xdac17f958d2ee523a2206206994597c13d831ec7`
            : `https://uniswap.info/pair/${pool.lpContract}`
        "
        target="_blank"
      >
        {{ $t("product.trade") }}
      </Button>
      <Deposit v-if="pool.name" :pool="pool" :type="depositType" />
      <Withdraw v-if="pool.name" :pool="pool" />
    </div>
  </div>
</template>

<style lang="scss">
.pool {
  text-align: center;
  &__icon {
    width: 15%;
  }
  &__items {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  &-item {
    width: 32%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3em 0;
    font-size: 1rem;
    text-align: center;
    margin: 0 2em 2em;
    @media screen and (max-width: $breakpoint-lg) {
      font-size: 1.5rem;
    }
    @media screen and (max-width: $breakpoint-sm) {
      width: 100%;
      padding: 2em;
    }
    &__title {
      color: $main-pink;
      font-size: 1.8em;
      overflow: hidden;
      margin-bottom: 1em;
      @media screen and (max-width: $breakpoint-sm) {
        margin-bottom: 0;
      }
    }
    &__balance {
      font-size: 4.2em;
      font-weight: 600;
      margin: auto 0 1.5em;
      @media screen and (max-width: $breakpoint-sm) {
        margin: 0 0 0.3em 0;
      }
    }
    &__btns {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
    }
    .btn {
      margin: 0 1rem;
      @media screen and (max-width: $breakpoint-lg) {
        font-size: 1.5em;
      }
      @media screen and (max-width: $breakpoint-sm) {
        flex-grow: 1;
      }
    }
  }
  &__trade-btn {
    margin-top: 4rem;
  }
}
</style>
