<script>
import BigNumber from 'bignumber.js';
import { getLpTokenPrice } from '@/utils/getPrice';
import Axios from 'axios';
import VueCookie from 'vue-cookie';
import Confirm from '@/components/Modals/Confirm';
import Button from '@/components/Button';
import InputComponent from '@/components/InputComponent';
import { amountFilter } from '../../helpers/filters';

export default {
  name: 'Deposit',
  components: {
    Button,
    Confirm,
    InputComponent,
  },
  props: {
    pool: {
      required: true,
    },
    type: {
      required: true,
    },
  },
  data: () => ({
    amount: 0,
    showConfirm: false,
    value: false,
  }),
  computed: {
    isLoading() {
      return this.$store.state.Actions.loading;
    },
    isDisabled() {
      const amount = parseFloat(this.amount);
      return (
        this.isLoading || !(amount > 0)
      );
    },
  },
  created() {
    this.$root.$on('showDeposit', this.showDeposit);
  },
  beforeDestroy() {
    this.$root.$off('showDeposit', this.showDeposit);
  },
  methods: {
    showDeposit() {
      this.value = true;
    },
    async sendAnalytics() {
      const weth = this.$contracts.getAddress('weth');
      const lpTokenPrice = await getLpTokenPrice(
        this.pool.uniContract || this.pool.lpContract,
        this.pool.tokenContract,
        weth,
      );
      const gaCode = VueCookie.get('_ga').substr(6);
      const amount = Math.round(lpTokenPrice * this.amount);
      Axios(`https://www.google-analytics.com/collect?v=1&t=event&tid=UA-176933670-1&cid=${gaCode}ec=deposits&ea=deposits&el=deposits&ev=${amount}`);
    },
    async deposit() {
      const depositAmount = new BigNumber(this.amount.replace(',', ''));
      if (this.pool.allowance.times(new BigNumber(10).pow(18))
        .comparedTo(depositAmount.times(new BigNumber(10).pow(18))) < 0) {
        this.pool.approve(depositAmount.times(new BigNumber(4))
          .times(new BigNumber(10).pow(18)).toString(10));
        return;
      }
      await this.$store.dispatch('Actions/deposit', {
        pid: this.pool.pid,
        amount: this.$contracts.toWei(depositAmount).toString(10),
        type: this.type,
        text: `${this.amount} ${this.pool.symbol}`,
        callback: () => {
          this.showConfirm = false;
          this.value = false;
        },
        provider: this.pool.nft || this.pool.type,
      });
      this.amount = 0;
    },
    maximize() {
      this.amount = amountFilter(this.pool.tokenBalance);
    },
  },
};
</script>

<template>
  <div>
    <v-dialog
      v-model="value"
    >
      <div class="main-block modal modal--light">
        <h4 class="modal__ttl modal__ttl--big">
          {{ $t('modals.withdraw-deposit.title-deposit') }} {{ pool.symbol }}
        </h4>
        <div class="modal__input">
          <InputComponent
            v-model="amount"
            @click="maximize"
            :max="true" >
            <template v-slot:label>
              {{ pool.tokenBalance | amount(18) }}
              {{ pool.symbol }}
              {{ $t('modals.withdraw-deposit.available') }}
            </template>
            <template v-slot:input>
              {{ pool.symbol }}
            </template>
          </InputComponent>
        </div>
        <div class="modal__btns">
          <Button @click="value = false">
            {{ $t('modals.withdraw-deposit.buttons.cancel') }}
          </Button>
          <Button
            :disabled="isDisabled"
            @click="showConfirm = true"
          >{{ isLoading ?
            $t('loading')
            :
            $t('modals.withdraw-deposit.buttons.confirm') }}
          </Button>
        </div>
      </div>
    </v-dialog>
    <!-- eslint-disable-next-line -->
    <Confirm
      :amount="amount"
      :symbol="pool.symbol"
      :action="deposit"
      :title="'Confirm Deposit'"
      :type="type"
      :isLoading="isLoading"
      :show.sync="showConfirm"
    />
  </div>
</template>
